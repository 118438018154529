.erpl_video {
    padding-bottom: 62.5%;
    height: 0px;
    .erpl_video-player {
        width: 100%;
        display: block;
        height: 100%;
        object-fit: fill;
        position: absolute!important;
    }
    .erpl_video-play-btn-container {
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        cursor: default!important;
        .erpl_video-play-btn {
            border: none;
            background: none !important;
            i {
                background: $gray-900;
                border-radius: 50%;
                width: 42px;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0.9;
                &.erpl_icon.erpl_icon-play {
                    -webkit-transform: scale(2.5);
                    transform: scale(2.5);
                    &:after {
                        background-position: -31px -436px;
                        width: 14px;
                        height: 21px;
                    }
                }
                &.erpl_icon.erpl_icon-play:hover {
                    background-color: $primary!important;
                    border: 1px solid $white;
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }
}