.erpl_submenu {
    border-bottom: 1px solid $border-color;
    div > ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            z-index: 1;
            display: flex;

            &[data-selected="true"] {

                a {
                    position: relative;
                    color: $dark;

                    &:after {
                        content: "";
                        display: block;
                        height: 98%;
                        width: auto;
                        background: $white;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        right: 0;
                        z-index: -1;
                        border-color: $border-color;
                        border-width: 1px 1px 0px 1px;
                        border-style: solid;
                    }
                }
            }


            a {
                color: $text-muted
            }


            
        }
    }
}