.bg-light-gradient {
	background: $gradient2
}

.bg-dark-blue-gradient {
	background: $gradient1;
}

.bg-dark-transparency {
	background: rgba(0,0,0,.7);
}

.bg-aqua-gradient {
	background-image: url('#{$assetsPath}/img/bg-aqua-gradient.svg');
	background-size: cover;
	background-color: #0c81c8;
}
