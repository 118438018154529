
//──── Animation des liens ───────────────────────────────────────────────────────────────
.a-i a,
.a-i .a-i-none .a-i a {
	color: $gray-900;
}
.a-i a:hover,
.a-i .a-i-none .a-i a:hover {
	color: $blue;
}

.a-i .a-i-none a,
.a-i .a-i-none .a-i .a-i-none a {
	color: $link-color;
}
.a-i .a-i-none a:hover,
.a-i .a-i-none .a-i .a-i-none a:hover {
	color: $link-hover-color;
}



[data-link-mode="true"] a:first-of-type:after {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	content: "";
	z-index: 9;
}