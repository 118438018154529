
//========================================================================================
/*                                                                                      *
 *                                        HEADER                                        *
 *                                                                                      */
//========================================================================================

header {
	background: #fafafa
}
/*  TopBar	*/
header #erpl_topbar .erpl_topbar-content  {
	height: 40px;
	font-size: .8125rem;
}

/* Custom Language selector Dropdown */
header #erpl_topbar .erpl_topbar-content .erpl_topbar-language-container {
	.erpl_dropdown {
		z-index: 99999;
		display: inline-block;
		.erpl_dropdown-btn {
			background: none;
			height: 40px;

			&:hover {
				.value {
					color: $primary
				}
			}

			

			.value {
				background: none;
				border: none;
				white-space: nowrap;
				padding-right: 0;
				padding: .375rem 0 .5rem .375rem;
				color: $gray-700
			}

			&.input-group {
				align-items: center;
				& >.input-group-append>i.erpl_icon {
					background: none;
					border: none
				}
			}

			&:not(.collapsed), &:hover {
				background: $gray-200;

				&.input-group {
					& >.input-group-append > i.erpl_icon:after {
						background-position: -175px -20px;

					}
				}
			}

			&:not(.collapsed) {

				&.input-group {
					& >.input-group-append > i.erpl_icon:after {
						transform: scaleY(-1);

					}
				}
			}
		}

		> div {
			position: absolute;

			.erpl_dropdown-content {
				background: $gray-200;
				transition: height .05s ease;
	
				ul.language-list {
					overflow-y: auto;
					overflow-x: hidden;
					max-height: calc(100vh - 40px);
					margin: 0;
					li {
	
						> * {
							padding: .625rem .75rem;
							display: block;
							white-space: nowrap;
							position: relative;

							&:after {
								display: block;
								height: 1px;
								background: $border-color;
								content: "";
								left: .75rem;
								right: .75rem;
								position: absolute;
								bottom: 0
							}

							&:focus {
								background: $gray-300;
								outline: none;
							}
						}

						&[data-selected="true"] {
							background: $white;

							> * {
								border-bottom: none;
								&:before {
									display: block;
									height: 1px;
									background: $primary;
									content: "";
									left: .75rem;
									right: .75rem;
									position: absolute;
									top: 0
								}

								&:after {
									background: $primary;
								}

								
							}
						}
					}
				}
			}
		}

		
	}
}

header #erpl_topbar .erpl_topbar-content  #erpl_topbar-language,
header #erpl_topbar .erpl_topbar-content  #erpl_otherwebsite-mobile {
	width: auto;
	height: 40px;
}

// erpl_otherwebsite
header #erpl_topbar .erpl_topbar-content .erpl_otherwebsite {
	& > ul > li a {
		height: 40px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		max-width: 100%;

		li {
			&#otherWebsiteListMobile {
				max-width: 100%;
				span {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					max-width: 100%;
				}
			}
			ul {
				display: none;
			}
		}

		li#otherWebsiteList:target,
		li#otherWebsiteListMobile:target {
			ul {
				display: block;
				position: absolute;
				z-index: 10000;
				background: $gray-201;
				min-width: 160px;
				padding: 0;
				right: 0;
				left: auto;
			}

			.close-menu:after {
				display: block;
				content: "";
				left: 0;
				position: fixed;
				right: 0;
				top: 0;
				bottom: 0;
				background: transparent;
				z-index: 9999;
				cursor: initial;
			}
		}

		li ul {
			a {
				border-bottom: 1px solid $gray-300;
				padding: .5rem .75rem;
				display: block;
			}
			
			li:last-child a {
				border-bottom: none;
			}
		}


		li#otherWebsiteList:target a[aria-haspopup="true"],
		li a[aria-haspopup="true"]:hover {
			background: $gray-200;

			.erpl_icon.erpl_icon-arrow-bottom-light:after {
				background-position: -175px -20px;
			}
		}

	}
}


header .erpl_logo-container {
	position: relative;

	&:after {
		content: "";
		width: 1px;
		background: $border-color;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		@media (max-width: map-get($map: $grid-breakpoints, $key: xl) - 1) {
			top: 5px;
		}
	
	}


	.erpl_logo {
		background: url('#{$assetsPath}/img/icons.svg');
		background-position: -2px -1px;
		height: 44px;
		width: 80px;
		display: block;
	
		@media (max-width: map-get($map: $grid-breakpoints, $key: xl) - 1) {
			transform: scale(.75);
		}
	}
}
.erpl_website-title .maintitle {
	font-size: 1.875rem;
	font-family: $font-family-serif;
	line-height: normal;

	@media (max-width: map-get($map: $grid-breakpoints, $key: xl) - 1) {
		font-size: 1.5625rem;
	}
}
.erpl_website-title .subtitle {
	line-height: normal
}
/* Topbar Select2 */

header .erpl_otherwebsite-other {
	max-width: 150px;
	width: auto;
}
header #erpl_topbar .select2-container {
	height: 40px;
}

header #erpl_topbar .select2-selection {
	height: 100%;
	border: none;
	display: flex;
	align-items : center;
	border-radius: 0;
	background: transparent;
}

header #erpl_topbar .select2-selection:hover,
header #erpl_topbar .select2-container--default.select2-container--open .select2-selection {
	background: $gray-200;
}

header #erpl_topbar .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: $secondary;
}

header #erpl_topbar  .select2-selection:hover .select2-selection__placeholder,
header #erpl_topbar  .select2-selection:hover .select2-selection__rendered {
    color: $primary;
}

header #erpl_topbar .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 100%;
}

header #erpl_topbar .select2-container--default .select2-selection--single {
	padding: 0.375rem 2rem 0.375rem 0.5rem
}



.select2-dropdown.header-style {
	background: $gray-200;
	border: none;
	font-size: .8125rem
}

.select2-dropdown.header-style .select2-results__option[data-selected=true]{
    background-color: $white;
    color: $primary;
}

.select2-dropdown.header-style .select2-results__option--highlighted[data-selected=false]:not(:hover){
    background-color: $gray-300;
    color: initial;
}

.select2-dropdown.header-style .select2-results__option[data-selected=false]{
    background-color: initial;
    color: initial;
}


.select2-dropdown.header-style  .select2-results__options {
	max-height: 600px;
	overflow-x: hidden
}

/* noJS show */
body[data-js="false"] .nojs-d-block {
	display: block !important
}
body[data-js="false"] .nojs-d-inline {
	display: inline !important
}
body[data-js="false"] .nojs-d-inline-block {
	display: inline-block !important
}
body[data-js="false"] .nojs-d-flex {
	display: flex !important
}
body[data-js="false"] .nojs-d-none {
	display: none !important
}


/* noJS Select2 */
body[data-js="false"] header .select2 {
	background: transparent;
	border: none;
	font-size: inherit;
}

body[data-js="true"] .nojs-only {
	display: none;
}
.navbar-container {
	height: 40px;

	&.small-nav-container {
		height: 20px;
	}
}

.navbar {
	padding: 0;

}
.navbar.bg-ep {
	background-color: #3d7ac1;
	border-bottom: 1px solid #3d7ac1;
background: linear-gradient(to bottom, #3d7ac1 0%,#345e9c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3d7ac1', endColorstr='#345e9c',GradientType=0 ); /* IE6-9 */
}

.navbar.box-shadow {
    box-shadow: 0 4px 5px rgba(0,0,0,.2);
}

/* Select2 Committee */
.select2-committee:not(.select2-dropdown) {
	width: 205px;
	height: 30px !important;
    margin-right: 5px;
    font-size: 13px;
    padding: 0.375rem 1.5rem 0.375rem 0.5rem;
    font-weight: 300;
}

body[data-js="false"] .select2-committee:not(.select2-dropdown) + button {
	display: block !important;
    position: absolute;
	right: 5px;
	top: 0;
	bottom: 0
}

.select2-committee-container .btn {
	margin-top: 3px;
	margin-right: 5px;
}

.select2-committee ul {
	max-height: 90vh
}

.select2-committee-container .select2-container--default .select2-selection--single {
	border-radius: 0;
    height: 30px;
    line-height: normal;
	font-size: 13px;
	font-weight: normal;
}
#mainMenu.active .select2-committee-container .select2-container--default .select2-selection--single {
	border-bottom: none;
	height: inherit;
	line-height: inherit
}

#mainNav.sticky .erpl_menu .select2-committee {
	max-width: 240px;
}

// @media (min-width: map-get($map: $grid-breakpoints, $key: xl)){
// 	body[data-menu-is-sticky="false"] .select2-dropdown.select2-committee {
// 		min-width: 33vw;
// 	}
// }


//──── Champ de recherche ────────────────────────────────────────────────────────────────
header .erpl_mainsearch {
	transition: 0.35s;
}
header .erpl_mainsearch:not(.active) .btn[disabled] {
	background: $gray-300;
	border-color: $gray-300;
	opacity: 1;
}

header .erpl_mainsearch:not(.active) .btn[disabled] i.erpl_icon-search-white:after {
    background-position: -146px -1px;
}


/* Absolute */
@media (max-width: map-get($map: $grid-breakpoints, $key: xl) - 1){
	header .erpl_mainsearch.abs {
		position: absolute;
		top: 195px;
		z-index: 999;
		left:0;
		right: 0;
		background: $gray-200;
		display: block;
		overflow: hidden;
		height: 0;
	}

	header .erpl_mainsearch.abs.active {
		position: absolute;
		height: 76px;
		overflow: initial;
	}


	/* noJS */
	body[data-js="false"] header #erpl_mainsearch:target{
		position: initial
	}
	body[data-js="false"] header #erpl_mainsearch:target .erpl_mainsearch{
		position: absolute;
		height: 76px;
		overflow: initial;
	}
	body[data-js="false"] header #erpl_mainsearch:target .input-group {
		max-width: calc(100% - 40px);
	}
}

/* Sticky */
header .erpl_mainsearch.sticky {
	position: fixed;
	top: -100px;
	z-index: 999;
	left:0;
	right: 0;
	display: block;
}

header .erpl_mainsearch.sticky.active {
	position: fixed;
	top: 38px;
}
/* Style 1 (default) : inherit from BS; */


/* Style Large */

header .erpl_mainsearch.erpl_mainsearch-large {
	background: $gray-200;
}

header .erpl_mainsearch.erpl_mainsearch-large input,
header .erpl_mainsearch.erpl_mainsearch-large input:focus {
	text-align: right;
	background: transparent;
	border: none;
	box-shadow: none;
	font-size: 1.2em;
	padding: 1.5rem;
}

header .erpl_mainsearch.erpl_mainsearch-large .btn-primary {
	background: transparent;
	border: none;
	padding: 0;
}

header .erpl_mainsearch.erpl_mainsearch-large .btn-primary:focus, 
header .erpl_mainsearch.erpl_mainsearch-large .btn-primary.focus,
header .erpl_mainsearch.erpl_mainsearch-large .btn-primary:not(:disabled):not(.disabled):active {
	border: none;
	box-shadow: none;
	background: transparent;
}

/* Transformation de l'icone */

header .erpl_mainsearch.erpl_mainsearch-large .btn-primary i:after {
	transform: scale(1.5) rotate(270deg);
    background-position: -175px -29px;
    width: 11px;
    height: 8px;	
}



/* Style Large devient le style par default + absolute sur breakpoint=xl */
@media (max-width: map-get($map: $grid-breakpoints, $key: xl)-1){
	header .erpl_mainsearch {
		background: $gray-200;
	}

	header .erpl_mainsearch input,
	header .erpl_mainsearch input:focus {
		text-align: right;
		background: transparent;
		border: none;
		box-shadow: none;
		font-size: 1.2em;
		padding: 1.5rem;
	}

	header .erpl_mainsearch .btn-primary {
		background: transparent;
		border: none;
		padding: 0;
	}

	header .erpl_mainsearch .btn-primary:focus, 
	header .erpl_mainsearch .btn-primary.focus,
	header .erpl_mainsearch .btn-primary:not(:disabled):not(.disabled):active {
		border: none;
		box-shadow: none;
		background: transparent;
	}

	header .erpl_mainsearch .btn-primary i:after {
		transform: scale(1.5) rotate(270deg);
	}	

	/* Transformation de l'icone */

	header .erpl_mainsearch .btn-primary i:after {
		transform: scale(1.5) rotate(270deg);
	    background-position: -175px -29px;
	    width: 11px;
	    height: 8px;	
	}	
}

//──── Menu ──────────────────────────────────────────────────────────────────────────────

header #mainNav ul#mainMenu {
	display: none;
	font-weight: 100;
}
header #mainNav ul#mainMenu.active {
	display: block;
	top: 100%;
	height: auto; 
	max-height: 600px;
	overflow-y: auto
	
}

@media (min-width: map-get($map: $grid-breakpoints, $key: xl)) {
	header #mainNav ul#mainMenu {
		display: flex;
		height: 100%;
		flex: 1 0 auto
	}
}

header .erpl_menu {
	position: relative;
	height: 100%;
	align-items: center;
	display: flex;
}

header .erpl_menu ul {
	list-style: none;
	padding: 0;
	margin: auto
}

header .erpl_menu ul > li {
	position: relative;
	display: flex
}

header .erpl_menu ul > li > a,
header .erpl_menu ul > li > div {
	display: flex;
}

header .erpl_menu ul > li > a > span,
header .erpl_menu ul > li > div > span:not(.select2) {
	padding: 0px 15px;
	display: flex !important;
	align-items: center;
	flex: 1 0 auto;
	width: 100%
}
header .erpl_menu > ul > li:first-child > a > span,
header .erpl_menu > ul > li:first-child > div > span {
	padding-left: 0;
}

header .erpl_menu ul > li > div span.select2 {
	margin: 0 5px 0 0 ;
}


header .erpl_menu ul > li > div > span.current-nav {
    background: $white;
    color: $red;
    padding-left: 1rem;
	padding-right: 1rem;
	font-size: $small-font-size;
	font-weight: bold;
}

header .erpl_menu ul#mainMenu.active > li > div:focus > span.current-nav {
    background: inherit;
}

header .erpl_menu ul > li > div > span.current-nav:after {
    display: block;
    height: 8px;
    width: 8px; 
    content: "";
    background: $white;
    position: absolute;
    right: -4px;
    top: calc(50% - 4px);
    transform: rotate(45deg)
}

header .erpl_menu.erpl_menu-vertical ul > li > div > span.current-nav:after {
    display: none;
}

header .erpl_menu ul > li > a > span > i,
header .erpl_menu ul > li > div > span > i {
	position: relative;
	left: 10px;
}


header .erpl_menu > ul > li > a,
header .erpl_menu > ul > li > div {
	color: $white;
	cursor: pointer;
	position: relative;
}

header .erpl_menu #mainMenu.active .erpl_menu-item-title:focus,
header .erpl_menu #mainMenu.active .erpl_menu-item ul li a:focus {
	background: $gray-200;
	outline: none;
	color: $primary !important;

}

header .erpl_menu #mainMenu:not(.active) .erpl_menu-item-title:focus {
	outline: none
}
header .erpl_menu #mainMenu:not(.active) .erpl_menu-item-title:focus span {
	transform: translateY(-4px);
	transition: transform .3s;
}

header .erpl_menu #mainMenu:not(.active) .erpl_menu-item ul li a:focus {
	outline: inherit;
	color: $primary !important;
}


header .erpl_menu > ul > li[data-selected="true"] > .erpl_menu-item-title:after {
    content: "";
    display: block;
    height: 4px;
    width: auto;
	background: $white;
	position: absolute;
	bottom: 0;
	left: 15px;
	right: 15px;
}

header .erpl_menu > ul > li:first-child[data-selected="true"] > .erpl_menu-item-title:after {
	left: 0;
}



/* Submenu */
header .erpl_menu ul ul {
	display: none;
	position: absolute;
	background: $white;
	min-width: 240px;
	box-sizing: border-box;
	max-width: 250px;
	box-shadow: 1px 2px 3px rgba(0,0,0,.2);
	top: 100%;
	left: 0;
	max-height: 480px;
	overflow-y: auto;
	overflow-x: hidden
}

header .erpl_menu ul li:hover ul,
header .erpl_menu ul li.active ul {
	display: block;
}

header .erpl_menu ul li ul li a {
	min-height: 40px;
	display: flex;
	width: 100%;
}

header .erpl_menu ul li ul li {
	border-left: 4px solid transparent;
}

header .erpl_menu ul li ul li[data-selected="true"] {
	border-left: 4px solid $primary;
}


//──── Menu vertical ou mode Sticky ──────────────────────────────────────────────────────

header #mainNav .mobile-tools {
	display: flex;
	flex: 1 0 auto;
}

header #mainNav .mobile-tools .current-nav {
    background: white;
    padding: 0 1rem;
    position: absolute;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
	color: #e53f1f;
	left: 0;
	z-index: 9;
	font-weight: bold;
	font-size: $small-font-size
}


@media (min-width: map-get($map: $grid-breakpoints, $key: xl)) {
	header #mainNav .mobile-tools {
		display: none;
	}
}

header #mainNav {
	z-index: 999;
	height: 40px;

	&:not(.sticky).small-nav {
		height: 20px;
	}
}

header #mainNav.sticky {
	position: fixed;
	width: 100%;
	top: 0 ;
}

header #mainNav.sticky .mobile-tools {
	display: flex;
	flex: 1 0 auto;
	min-height: 30px
}

header #mainNav.sticky #mainMenu {
	display: none;
}



header #mainNav #mainMenu.active {
    display: block;
    position: absolute;
    right: 0;
    background: $white;
    box-shadow: 0 4px 5px rgba(0,0,0,.2);
    min-width: 280px;
}

header #mainNav.sticky .erpl_menu > ul > li > a:after ,
header #mainNav .erpl_menu.erpl_menu-vertical > ul > li > a:after {
    background: transparent;
    height: auto;
    width: 4px;
    display: block;
    content: "";
}

header #mainNav.sticky .erpl_menu > ul > li[data-selected="true"] > .erpl_menu-item-title:after,
header #mainNav .erpl_menu.erpl_menu-vertical > ul > li[data-selected="true"] > .erpl_menu-item-title:after {
	background: $primary;
    height: auto;
    width: 4px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}

header #mainNav.sticky .erpl_menu > ul > li.active[data-selected="true"] > .erpl_menu-item-title:after,
header #mainNav .erpl_menu.erpl_menu-vertical > ul > li.active[data-selected="true"] > .erpl_menu-item-title:after{
    background: transparent;
}



header #mainNav.sticky .erpl_menu > ul > li > a > span,
header #mainNav.sticky .erpl_menu > ul > li > div > span,
header #mainNav .erpl_menu.erpl_menu-vertical > ul > li > a > span,
header #mainNav .erpl_menu.erpl_menu-vertical > ul > li > div > span {
	order: 1;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    width: 98%;
    justify-content: space-between;
}

header #mainNav.sticky .erpl_menu > ul > li > ul > li:not(:last-child) > a:after,
header #mainNav .erpl_menu.erpl_menu-vertical > ul > li > ul > li:not(:last-child) > a:after  {
	border-bottom: 1px solid $border-color;
	content: "";
	display: block;
	position: absolute;
	left: 15px;
	right: 15px;
	bottom: 0;

}

header #mainNav.sticky  .erpl_menu ul ul ,
header #mainNav   .erpl_menu.erpl_menu-vertical ul ul {
	position: initial;
}

header #mainNav #mainMenu.active .erpl_menu-item-title i {
    position: relative;
    left: 0px;
    display: flex;
    border: 1px solid $primary;
    padding: 3px;
    border-radius: 50%;
    height: 17px;
    width: 17px;
    align-items: center
}

header #mainNav #mainMenu.active .erpl_menu-item-title[aria-expanded="true"] i.erpl_icon-more:after {
    background-position: -82px -100px;
    width: 9px;
    height: 1px;
}


header #mainNav.sticky #mainMenu.active li > div,
header #mainNav .erpl_menu-vertical #mainMenu.active li > div {
    color: $secondary;
    display: flex;
	cursor: pointer;
	flex: 1 0 auto
}

header #mainNav .erpl_menu-vertical .select2-committee + .select2-container {
	width: 100% !important;
	padding: 0;
	margin: 0;
}
header #mainNav.sticky #mainMenu.active li > a,
header #mainNav .erpl_menu-vertical #mainMenu.active li > a {
    color: $secondary;
	flex: 1 0 auto;
	flex-direction: column
}

header #mainNav.sticky #mainMenu.active a:hover,
header #mainNav .erpl_menu-vertical #mainMenu.active a:hover {
    color: $primary;
}

header #mainNav .sticky-btn {
	background: $dark-primary;
	color: $white;
	border-color: $dark-primary;
	text-transform: uppercase;
	transition: 0
}

header #mainNav .sticky-btn:hover,
header #mainNav .sticky-btn.active {
	background-color: $gray-200;
    border-color: $gray-200;
    color: $primary ;
    position: relative;
}

header #mainNav #mobileMainMenuToogle.sticky-btn.active {
	background-color: $white;
    border-color: $white;
}

header #mainNav .sticky-btn.active {
	border-radius: 0.2rem 0.2rem 0 0;
}

header #mainNav .sticky-btn:after {
	background-color: $gray-200;
    content: "";
    display: block; 
    position: absolute;
    height: 0px;
    bottom: -10px;
    left: -1px;
    right: -1px;
    opacity: 0;
    transition: 0.35s
}

header #mainNav #mobileMainMenuToogle.sticky-btn:after {
	background-color: $white;
}

header #mainNav .sticky-btn.active:after {
	opacity: 1;
	height: 10px
}

header #mainNav .sticky-btn:hover i.erpl_icon.erpl_icon-bars-white:after, 
header #mainNav .sticky-btn.active i.erpl_icon.erpl_icon-bars-white:after {
	background-position: -82px -66px;
}

header #mainNav .sticky-btn:hover i.erpl_icon.erpl_icon-search-white:after,
header #mainNav .sticky-btn.active i.erpl_icon.erpl_icon-search-white:after {
	background-position: -83px -1px;
}


header #mainNav .sticky-title {
	color: $white;
	display: none;
}

header #mainNav.sticky .sticky-title {
	display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

@media (max-width: map-get($map: $grid-breakpoints, $key: md)){
	header #mainNav.sticky .sticky-title span {
		display: none;
	}
	header #mainNav.sticky .sticky-title:after{
		content: "";
		background: url('#{$assetsPath}/img/icons.svg');
		height: 46px;
		width: 82px;
		display: block;
		transform: translate(-5px,-3px) scale(.55);
		background-position: left -46px;
		position: absolute;
	    left: 0;
	    top: -6px;
	}	
	.current-nav + .sticky-title-container .sticky-title:after{
		display: none !important; 
	}	
}


.navbar > .container, .navbar > .container-fluid{
	display: block;
	height: 100%
}

@media (max-width: map-get($map: $grid-breakpoints, $key: xl)-1){
	.navbar > .container, .navbar.sticky > .container-fluid {
		display: block
	}	
}

header #mainNav.sticky .erpl_menu ul ul,
header #mainNav .erpl_menu.erpl_menu-vertical ul ul {
	max-width: 100%;
	width: 100%;
}

header #mainNav.sticky .erpl_menu ul li:hover ul,
header #mainNav .erpl_menu.erpl_menu-vertical ul li:hover ul {
	display: none;
}

header #mainNav.sticky .erpl_menu ul li.active a,
header #mainNav .erpl_menu.erpl_menu-vertical ul li.active div {
	// display: block;
	background: $gray-200
}

header #mainNav.sticky .erpl_menu > ul > li,
header #mainNav .erpl_menu.erpl_menu-vertical > ul > li {
	border-bottom: 1px solid $border-color;
	color: $secondary;
	display: block;
}

header #mainNav.sticky .erpl_menu > ul > li > a,
header #mainNav.sticky .erpl_menu > ul > li > div,
header #mainNav .erpl_menu.erpl_menu-vertical > ul > li > a,
header #mainNav .erpl_menu.erpl_menu-vertical > ul > li > div {
	font-weight: bold;
}


header #mainNav.sticky .erpl_menu ul li.active ul,
header #mainNav .erpl_menu.erpl_menu-vertical ul li.active ul {
	display: block;
	background: $gray-200;
	box-shadow: none;
	position: static;
}

//──── Menu noJS vertical ────────────────────────────────────────────────────────────────
@media (max-width: map-get($map: $grid-breakpoints, $key: xl)-1){
	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target > ul#mainMenu {
		display: block;
	    position: absolute;
	    right: 0;
	    background: $white;
	    box-shadow: 0 4px 5px rgba(0,0,0,.2);
	    min-width: 280px;
	}
	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target .mr-4 {
		margin: 0 !important
	}

	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target > ul#mainMenu ul {
		display: block;
		position: initial;
		box-shadow: none;
		max-width: none;
		background: $gray-200
	}

	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target > ul#mainMenu > li {
	    border-bottom: 1px solid $border-color;
		color: $secondary;
		display: block;
	}	

	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target > ul#mainMenu li > a, 
	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target > ul#mainMenu li > div {
	    color: $secondary;
	    display: block;
	}

	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target > ul#mainMenu > li > a > span, 
	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target > ul#mainMenu > li > div > span {
	    order: 1;
	    padding: 10px 20px;
	    display: flex;
	    align-items: center;
	    width: 100%;
	    justify-content: space-between;
	}
	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target > ul#mainMenu > li > a, 
	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target > ul#mainMenu > li > div {
	    font-weight: bold;
	    position: relative;
	}

	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target > ul#mainMenu > li > a:after {
	    background: transparent;
	    height: auto;
	    width: 4px;
	    display: block;
	    content: "";
	}

	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target > ul#mainMenu > li[data-selected="true"] > .erpl_menu-item-title:after{
	    background: $primary;
	    height: auto;
	    width: 4px;
	    position: absolute;
	    left: 0;
	    top: 0;
	    bottom: 0;
	}	

	body[data-js=false] header #mainNav .erpl_menu#erpl_menu:target ul span > i.erpl_icon:after {
		background-position: -175px -20px;
	    width: 11px;
	    height: 8px;
	}

}



header {

	[data-mode="tab"] {
	
		.erpl_menu > ul > li:first-child > a > span,
		.erpl_menu > ul > li:first-child > div > span {
			padding-left: 15px;
		}
	
		.erpl_menu > ul > li {

			&[data-selected="true"] > {
				a,
				div {
					color: $primary;
				}
		
				.erpl_menu-item-title:after {
					content: "";
					display: block;
					height: 98%;
					width: auto;
					background: $white;
					position: absolute;
					bottom: -1px;
					left: 0;
					right: 0;
					z-index: -1;
				}
	
				
			}

			&[data-selected="true"][data-has-submenu="true"] {
				.erpl_menu-item-title:after {
					background: $gray-100;
				}
			}
		}
	
	
	}
	
}
