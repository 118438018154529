//──── ERPL Badges ───────────────────────────────────────────────────────────────────────
.erpl_badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
    min-width: 43px;
}

.erpl_badge-committee, .erpl_badge-outline-red {
    color: $red;
    border: 1px solid $red;
    background-color: $white;
}

.erpl_badge-blue {
    background-color: $primary;
    color: $white;
    font-weight: normal;
    padding: 5px 7px;
}
.erpl_badge-red {
    background-color: $red;
    color: $white;
    font-weight: normal;
    padding: 5px 7px;
}
.erpl_badge-outline-blue {
    color: $primary;
    border: 1px solid $primary;
    background-color: $white;
}


.erpl_badge-language {
    border: 1px solid $primary;
    color: $primary;
    min-width: 17px;
    line-height: 1;
}

a:hover.erpl_badge.erpl_badge-committee {
    color: $white!important;
    background-color: $primary!important;
    border: 1px solid $primary!important;
}

[data-link-mode="true"] .badges a::after {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    z-index: 9;
}