//──── ICONS ─────────────────────────────────────────────────────────────────────────────
i.erpl_icon.erpl_icon-2x {
    transform: scale(2);
}

i.erpl_icon.erpl_icon-3x {
    transform: scale(3);
}

i.erpl_icon.erpl_icon-1-5x {
    transform: scale(1.5);
}

i.erpl_icon {
    display: inline-block;
}

i.erpl_icon:after {
    background: url('#{$assetsPath}/img/icons.svg');
    display: block;
    content: "";
}

i.erpl_icon.rotate-45:after {
    transform: rotate(45deg);
}

i.erpl_icon.rotate-90:after {
    transform: rotate(90deg);
}

i.erpl_icon.rotate-180:after {
    transform: rotate(180deg);
}

i.erpl_icon.rotate-270:after {
    transform: rotate(270deg);
}

i.erpl_icon.flip-x:after {
    transform: scaleX(-1);
}

i.erpl_icon.flip-y:after {
    transform: scaleY(-1);
}

// Search
i.erpl_icon-search-blue:after {
    background-position: -83px -1px;
    width: 18px;
    height: 19px;
}

i.erpl_icon-search-white:after {
    background-position: -104px -1px;
    width: 18px;
    height: 19px;
}

i.erpl_icon-search-dark:after {
    background-position: -125px -1px;
    width: 18px;
    height: 19px;
}

i.erpl_icon-search-light:after {
    background-position: -146px -1px;
    width: 18px;
    height: 19px;
}

// Website
i.erpl_icon-website-dark::after {
    background-position: 45px 352px;
    width: 20px;
    height: 21px;
}

// Arrow
i.erpl_icon-arrow-bottom-blue:after {
    background-position: -175px -20px;
    width: 11px;
    height: 8px;
}

i.erpl_icon-arrow-bottom-white:after {
    background-position: -175px -2px;
    width: 11px;
    height: 8px;
}

i.erpl_icon-arrow-bottom-dark:after {
    background-position: -175px -11px;
    width: 11px;
    height: 8px;
}

i.erpl_icon-arrow-bottom-light:after {
    background-position: -175px -29px;
    width: 11px;
    height: 8px;
}

// Social
i.erpl_icon-telegram:after,
i.erpl_icon-telegram-blue:after {
    background-position: -131px -538px;
    width: 15px;
    height: 12px;
}

i.erpl_icon-telegram-white:after {
    background-position: -150px -538px;
    width: 15px;
    height: 12px;
}

i.erpl_icon-telegram-dark:after {
    background-position: -112px -538px;
    width: 15px;
    height: 12px;
}

i.erpl_icon-telegram-light:after {
    background-position: -169px -538px;
    width: 15px;
    height: 12px;
}

i.erpl_icon-whatsapp:after,
i.erpl_icon-whatsapp-green:after {
    background-position: -1px -586px;
    width: 18px;
    height: 18px;
}

i.erpl_icon-whatsapp-white:after {
    background-position: -23px -586px;
    width: 18px;
    height: 18px;
}

i.erpl_icon-whatsapp-dark:after {
    background-position: -67px -586px;
    width: 18px;
    height: 18px;
}

i.erpl_icon-whatsapp-light:after {
    background-position: -45px -586px;
    width: 18px;
    height: 18px;
}

i.erpl_icon-facebook:after,
i.erpl_icon-facebook-blue:after {
    background-position: -114px -44px;
    width: 9px;
    height: 18px;
}

i.erpl_icon-facebook-white:after {
    background-position: -135px -44px;
    width: 9px;
    height: 18px;
}

i.erpl_icon-facebook-dark:after {
    background-position: -156px -44px;
    width: 9px;
    height: 18px;
}

i.erpl_icon-facebook-light:after {
    background-position: -177px -44px;
    width: 9px;
    height: 18px;
}

i.erpl_icon-reddit:after {
    background-position: -108px -62px;
    width: 21px;
    height: 18px;
}

i.erpl_icon-reddit-white:after {
    background-position: -129px -62px;
    width: 21px;
    height: 18px;
}

i.erpl_icon-reddit-dark:after {
    background-position: -150px -62px;
    width: 21px;
    height: 18px;
}

i.erpl_icon-reddit-light:after {
    background-position: -171px -62px;
    width: 21px;
    height: 18px;
}

i.erpl_icon-twitter:after,
i.erpl_icon-twitter-blue:after {
    background-position: -111px -146px;
    width: 15px;
    height: 13px;
}

i.erpl_icon-twitter-white:after {
    background-position: -132px -146px;
    width: 15px;
    height: 13px;
}

i.erpl_icon-twitter-dark:after {
    background-position: -153px -146px;
    width: 15px;
    height: 13px;
}

i.erpl_icon-twitter-light:after {
    background-position: -174px -146px;
    width: 15px;
    height: 13px;
}

i.erpl_icon-flickr:after,
i.erpl_icon-flickr-blue:after {
    background-position: -108px -87px;
    width: 21px;
    height: 10px;
}

i.erpl_icon-flickr-white:after {
    background-position: -129px -87px;
    width: 21px;
    height: 10px;
}

i.erpl_icon-flickr-dark:after {
    background-position: -150px -87px;
    width: 21px;
    height: 10px;
}

i.erpl_icon-flickr-light:after {
    background-position: -171px -87px;
    width: 21px;
    height: 10px;
}

i.erpl_icon-linkedin:after,
i.erpl_icon-linkedin-blue:after {
    background-position: -111px -105px;
    width: 15px;
    height: 14px;
}

i.erpl_icon-linkedin-white:after {
    background-position: -132px -105px;
    width: 15px;
    height: 14px;
}

i.erpl_icon-linkedin-dark:after {
    background-position: -153px -105px;
    width: 15px;
    height: 14px;
}

i.erpl_icon-linkedin-light:after {
    background-position: -174px -105px;
    width: 15px;
    height: 14px;
}

i.erpl_icon-youtube:after {
    background-position: -109px -126px;
    width: 19px;
    height: 12px;
}

i.erpl_icon-youtube-white:after {
    background-position: -130px -126px;
    width: 19px;
    height: 12px;
}

i.erpl_icon-youtube-dark:after {
    background-position: -151px -126px;
    width: 19px;
    height: 12px;
}

i.erpl_icon-youtube-light:after {
    background-position: -172px -126px;
    width: 19px;
    height: 12px;
}

i.erpl_icon-instagram:after {
    background-position: -109px -163px;
    width: 19px;
    height: 18px;
}

i.erpl_icon-instagram-white:after {
    background-position: -130px -163px;
    width: 19px;
    height: 18px;
}

i.erpl_icon-instagram-dark:after {
    background-position: -151px -163px;
    width: 19px;
    height: 18px;
}

i.erpl_icon-instagram-light:after {
    background-position: -172px -163px;
    width: 19px;
    height: 18px;
}

i.erpl_icon-pinterest:after {
    background-position: -110px -183px;
    width: 17px;
    height: 18px;
}

i.erpl_icon-pinterest-white:after {
    background-position: -131px -183px;
    width: 17px;
    height: 18px;
}

i.erpl_icon-pinterest-dark:after {
    background-position: -152px -183px;
    width: 17px;
    height: 18px;
}

i.erpl_icon-pinterest-light:after {
    background-position: -173px -183px;
    width: 17px;
    height: 18px;
}

i.erpl_icon-snapchat:after {
    background-position: -110px -204px;
    width: 17px;
    height: 16px;
}

i.erpl_icon-snapchat-white:after {
    background-position: -131px -204px;
    width: 17px;
    height: 16px;
}

i.erpl_icon-snapchat-dark:after {
    background-position: -152px -204px;
    width: 17px;
    height: 16px;
}

i.erpl_icon-snapchat-light:after {
    background-position: -173px -204px;
    width: 17px;
    height: 16px;
}

i.erpl_icon-google:after {
    background-position: -110px -226px;
    width: 17px;
    height: 12px;
}

i.erpl_icon-google-white:after {
    background-position: -131px -226px;
    width: 17px;
    height: 12px;
}

i.erpl_icon-google-dark:after {
    background-position: -152px -226px;
    width: 17px;
    height: 12px;
}

i.erpl_icon-google-light:after {
    background-position: -173px -226px;
    width: 17px;
    height: 12px;
}

i.erpl_icon-soundcloud:after,
i.erpl_icon-soundcloud-blue:after {
    background-position: -3px -327px;
    width: 25px;
    height: 12px;
}

i.erpl_icon-soundcloud-white:after {
    background-position: -32px -327px;
    width: 25px;
    height: 12px;
}

i.erpl_icon-soundcloud-dark:after {
    background-position: -61px -327px;
    width: 25px;
    height: 12px;
}

i.erpl_icon-soundcloud-light:after {
    background-position: -90px -327px;
    width: 25px;
    height: 12px;
}

// Bar menu
i.erpl_icon-bars-blue:after {
    background-position: -82px -66px;
    width: 15px;
    height: 12px;
}

i.erpl_icon-bars-dark:after {
    background-position: -82px -54px;
    width: 15px;
    height: 12px;
}

i.erpl_icon-bars-white:after {
    background-position: -82px -42px;
    width: 15px;
    height: 12px;
}

// Plus/Minus
i.erpl_icon-plus:after {
    background-position: -1px -93px;
    width: 16px;
    height: 16px;
}

i.erpl_icon-plus-white:after {
    background-position: -37px -93px;
    width: 16px;
    height: 16px;
}

i.erpl_icon-minus:after {
    background-position: -19px -100px;
    width: 16px;
    height: 2px;
}

i.erpl_icon-minus-white:after {
    background-position: -55px -100px;
    width: 16px;
    height: 2px;
}

// Plus BOLD
i.erpl_icon-plus-bold:after,
i.erpl_icon-plus-bold-blue:after {
    background-position: -3px -465px;
    width: 19px;
    height: 19px;
}

i.erpl_icon-plus-bold-white:after {
    background-position: -24px -465px;
    width: 19px;
    height: 19px;
}

i.erpl_icon-plus-bold-dark:after {
    background-position: -45px -465px;
    width: 19px;
    height: 19px;
}

i.erpl_icon-plus-bold-success:after {
    background-position: -66px -465px;
    width: 19px;
    height: 19px;
}

// More/less
i.erpl_icon-more:after,
i.erpl_icon-more-blue:after {
    background-position: -82px -96px;
    width: 9px;
    height: 9px;
}

i.erpl_icon-more-white:after {
    background-position: -82px -78px;
    width: 9px;
    height: 9px;
}

i.erpl_icon-more-dark:after {
    background-position: -82px -87px;
    width: 9px;
    height: 9px;
}

i.erpl_icon-more-light:after {
    background-position: -82px -105px;
    width: 9px;
    height: 9px;
}

i.erpl_icon-less:after,
i.erpl_icon-less-blue:after {
    background-position: -82px -100px;
    width: 9px;
    height: 1px;
}

i.erpl_icon-less-white:after {
    background-position: -82px -82px;
    width: 9px;
    height: 1px;
}

i.erpl_icon-less-dark:after {
    background-position: -82px -91px;
    width: 9px;
    height: 1px;
}

i.erpl_icon-less-light:after {
    background-position: -82px -109px;
    width: 9px;
    height: 1px;
}

// Documents
i.erpl_icon-pdf:after {
    background-position: -137px -610px;
    width: 20px;
    height: 15px;
}

i.erpl_icon-doc:after {
    background-position: -166px -610px;
    width: 20px;
    height: 15px;
}

// xml
i.erpl_icon-xml:after {
    background-position: -166px -589px;
    width: 20px;
    height: 15px;
}

// Image
i.erpl_icon-image-blue:after {
    background-position: -2px -610px;
    width: 20px;
    height: 15px;
}

i.erpl_icon-image:after {
    background-position: -84px -610px;
    width: 20px;
    height: 15px;
}

i.erpl_icon-image-dark:after {
    background-position: -84px -610px;
    width: 20px;
    height: 15px;
}

i.erpl_icon-image-light:after {
    background-position: -57px -610px;
    width: 20px;
    height: 15px;
}

i.erpl_icon-image-green:after {
    background-position: -111px -610px;
    width: 20px;
    height: 15px;
}

i.erpl_icon-image-white:after {
    background-position: -29px -610px;
    width: 20px;
    height: 15px;
}

// Thumb/List
i.erpl_icon-list:after,
i.erpl_icon-list-dark:after {
    background-position: -52px -188px;
    width: 19px;
    height: 19px;
}

i.erpl_icon-list-white:after {
    background-position: -52px -207px;
    width: 19px;
    height: 19px;
}

i.erpl_icon-thumb:after,
i.erpl_icon-thumb-dark:after {
    background-position: -71px -188px;
    width: 19px;
    height: 19px;
}

i.erpl_icon-thumb-white:after {
    background-position: -71px -207px;
    width: 19px;
    height: 19px;
}

// Calendar
i.erpl_icon-calendar-blue:after,
i.erpl_icon-calendar:after {
    background-position: -1px -234px;
    width: 20px;
    height: 22px;
}

i.erpl_icon-calendar-white:after {
    background-position: -45px -234px;
    width: 20px;
    height: 22px;
}

i.erpl_icon-calendar-light:after {
    background-position: -23px -234px;
    width: 20px;
    height: 22px;
}

i.erpl_icon-calendar2-blue:after,
i.erpl_icon-calendar2:after {
    background-position: -2px -304px;
    width: 18px;
    height: 21px;
}

i.erpl_icon-calendar2-white:after {
    background-position: -22px -304px;
    width: 18px;
    height: 21px;
}

i.erpl_icon-calendar-dark::after {
    background-position: 70px -306px;
    width: 18px;
    height: 21px;
}

i.erpl_icon-calendar2-dark:after {
    background-position: -42px -304px;
    width: 18px;
    height: 21px;
}

i.erpl_icon-calendar2-light:after {
    background-position: -62px -304px;
    width: 18px;
    height: 21px;
}

i.erpl_icon-calendar3-blue:after,
i.erpl_icon-calendar3:after {
    background-position: -83px -307px;
    width: 16px;
    height: 16px;
}

i.erpl_icon-calendar3-white:after {
    background-position: -103px -307px;
    width: 16px;
    height: 16px;
}

i.erpl_icon-calendar3-dark:after {
    background-position: -123px -307px;
    width: 16px;
    height: 16px;
}

i.erpl_icon-calendar3-light:after {
    background-position: -143px -307px;
    width: 16px;
    height: 16px;
}

// Planet
i.erpl_icon-planet-blue:after,
i.erpl_icon-planet:after {
    background-position: -3px -282px;
    width: 20px;
    height: 21px;
}

i.erpl_icon-planet-white:after {
    background-position: -27px -282px;
    width: 20px;
    height: 21px;
}

i.erpl_icon-planet-dark:after {
    background-position: -51px -282px;
    width: 20px;
    height: 21px;
}

i.erpl_icon-planet-light:after {
    background-position: -75px -282px;
    width: 20px;
    height: 21px;
}

i.erpl_icon-planet2-blue:after,
i.erpl_icon-planet2:after {
    background-position: -98px -282px;
    width: 21px;
    height: 21px;
}

i.erpl_icon-planet2-white:after {
    background-position: -122px -282px;
    width: 21px;
    height: 21px;
}

i.erpl_icon-planet2-dark:after {
    background-position: -146px -282px;
    width: 21px;
    height: 21px;
}

i.erpl_icon-planet2-light:after {
    background-position: -169px -282px;
    width: 21px;
    height: 21px;
}

i.erpl_icon-phone:after {
    background-position: -45px -156px;
    width: 18px;
    height: 17px;
}

i.erpl_icon-twitter-outline:after {
    background-position: -45px -141px;
    width: 18px;
    height: 15px;
}

i.erpl_icon-facebook-outline:after {
    background-position: -47px -259px;
    width: 18px;
    height: 20px;
}

i.erpl_icon-mobile:after {
    background-position: -63px -140px;
    width: 16px;
    height: 20px;
}

i.erpl_icon-fax:after {
    background-position: -63px -160px;
    width: 19px;
    height: 19px;
}

i.erpl_icon-mail-dark::after {
    background-position: 212px 392px;
    width: 20px;
    height: 21px;
}

i.erpl_icon-enveloppe:after {
    background-position: -80px -118px;
    width: 15px;
    height: 12px;
}

i.erpl_icon-enveloppe-outline:after {
    background-position: -91px -189px;
    width: 18px;
    height: 12px;
}

i.erpl_icon-play:after,
i.erpl_icon-play-white:after {
    background-position: -90px -135px;
    width: 15px;
    height: 18px;
}

i.erpl_icon-play-mini:after,
i.erpl_icon-play-mini-white:after {
    background-position: -100px -150px;
    width: 8px;
    height: 9px;
}

i.erpl_icon-play-mini-blue:after {
    background-position: -100px -97px;
    width: 8px;
    height: 10px;
}

i.erpl_icon-play-mini-dark:after {
    background-position: -100px -107px;
    width: 8px;
    height: 10px;
}

i.erpl_icon-play-mini-light:after {
    background-position: -100px -117px;
    width: 8px;
    height: 10px;
}

i.erpl_icon-play-mini-success:after {
    background-position: -100px -127px;
    width: 8px;
    height: 10px;
}

i.erpl_icon-play-outline:after,
i.erpl_icon-play-outline-blue:after {
    background-position: -24px -257px;
    width: 19px;
    height: 21px;
}

i.erpl_icon-play-outline-white:after {
    background-position: -2px -257px;
    width: 19px;
    height: 21px;
}

i.erpl_icon-tag:after {
    background-position: -65px -110px;
    width: 12px;
    height: 12px;
}

i.erpl_icon-tag-white:after {
    background-position: -65px -122px;
    width: 12px;
    height: 12px;
}

// Assistants
i.erpl_icon-assistant:after,
i.erpl_icon-assistant-dark:after {
    background-position: -166px -365px;
    width: 24px;
    height: 25px;
}

i.erpl_icon-assistant-light:after,
i.erpl_icon-assistant-trainee:after {
    background-position: -139px -365px;
    width: 24px;
    height: 25px;
}

i.erpl_icon-assistant-purple:after,
i.erpl_icon-assistant-paying-agent:after {
    background-position: -112px -365px;
    width: 24px;
    height: 25px;
}

i.erpl_icon-assistant-blue:after,
i.erpl_icon-assistant-service-providers:after {
    background-position: -84px -365px;
    width: 24px;
    height: 25px;
}

i.erpl_icon-assistant-green:after,
i.erpl_icon-assistant-local:after {
    background-position: -57px -365px;
    width: 24px;
    height: 25px;
}

i.erpl_icon-assistant-orange:after,
i.erpl_icon-assistant-accredited:after {
    background-position: -30px -365px;
    width: 24px;
    height: 25px;
}

i.erpl_icon-assistant-light-blue:after,
i.erpl_icon-assistant-vice-presidency:after {
    background-position: -3px -365px;
    width: 24px;
    height: 25px;
}

// Blog
i.erpl_icon-blog:after,
i.erpl_icon-blog-blue:after {
    background-position: -3px -342px;
    width: 20px;
    height: 21px;
}

i.erpl_icon-blog-white:after {
    background-position: -26px -342px;
    width: 20px;
    height: 21px;
}

i.erpl_icon-blog-dark:after {
    background-position: -51px -342px;
    width: 20px;
    height: 21px;
}

i.erpl_icon-blog-light:after {
    background-position: -74px -342px;
    width: 20px;
    height: 21px;
}

i.erpl_icon-blog-star:after,
i.erpl_icon-blog-star-blue:after {
    background-position: -99px -343px;
    width: 19px;
    height: 18px;
}

i.erpl_icon-blog-star-white:after {
    background-position: -123px -343px;
    width: 19px;
    height: 18px;
}

i.erpl_icon-blog-star-dark:after {
    background-position: -147px -343px;
    width: 19px;
    height: 18px;
}

i.erpl_icon-blog-star-light:after {
    background-position: -171px -343px;
    width: 19px;
    height: 18px;
}

i.erpl_icon-check:after,
i.erpl_icon-check-blue:after {
    background-position: -3px -394px;
    width: 16px;
    height: 14px;
}

i.erpl_icon-check-white:after {
    background-position: -23px -394px;
    width: 16px;
    height: 14px;
}

i.erpl_icon-check-dark:after {
    background-position: -43px -394px;
    width: 16px;
    height: 14px;
}

i.erpl_icon-check-light:after {
    background-position: -63px -394px;
    width: 16px;
    height: 14px;
}

i.erpl_icon-check-success:after {
    background-position: -83px -394px;
    width: 16px;
    height: 14px;
}

// Close
i.erpl_icon-close:after,
i.erpl_icon-close-blue:after {
    background-position: -90px -224px;
    width: 12px;
    height: 12px;
}

i.erpl_icon-close-white:after {
    background-position: -90px -212px;
    width: 12px;
    height: 12px;
}

i.erpl_icon-close-light:after {
    background-position: -90px -200px;
    width: 12px;
    height: 12px;
}

// Info
i.erpl_icon-info:after,
i.erpl_icon-info-white:after {
    background-position: -77px -140px;
    width: 9px;
    height: 21px;
}

// Player
// - next
i.erpl_icon-next:after,
i.erpl_icon-next-blue:after {
    background-position: -3px -458px;
    width: 20px;
    height: 29px;
}

i.erpl_icon-next-white:after {
    background-position: -41px -458px;
    width: 20px;
    height: 29px;
}

i.erpl_icon-next-light:after {
    background-position: -119px -458px;
    width: 20px;
    height: 29px;
}

i.erpl_icon-next-dark:after {
    background-position: -80px -458px;
    width: 20px;
    height: 29px;
}

// - Step
i.erpl_icon-step-forward:after,
i.erpl_icon-step-forward-blue:after {
    background-position: -3px -436px;
    width: 21px;
    height: 19px;
}

i.erpl_icon-step-forward-white:after {
    background-position: -30px -436px;
    width: 21px;
    height: 19px;
}

i.erpl_icon-step-forward-dark:after {
    background-position: -58px -436px;
    width: 21px;
    height: 19px;
}

i.erpl_icon-step-forward-success:after {
    background-position: -86px -436px;
    width: 21px;
    height: 19px;
}

// - Fast
i.erpl_icon-fast-forward:after,
i.erpl_icon-fast-forward-blue:after {
    background-position: -3px -415px;
    width: 35px;
    height: 19px;
}

i.erpl_icon-fast-forward-white:after {
    background-position: -41px -415px;
    width: 35px;
    height: 19px;
}

i.erpl_icon-fast-forward-dark:after {
    background-position: -79px -415px;
    width: 35px;
    height: 19px;
}

i.erpl_icon-fast-forward-success:after {
    background-position: -117px -415px;
    width: 35px;
    height: 19px;
}

// - LOCK
i.erpl_icon-locked:after,
i.erpl_icon-locked-blue:after {
    background-position: -3px -489px;
    width: 13px;
    height: 15px;
}

i.erpl_icon-locked-white:after {
    background-position: -19px -489px;
    width: 13px;
    height: 15px;
}

i.erpl_icon-locked-success:after {
    background-position: -52px -489px;
    width: 13px;
    height: 15px;
}

i.erpl_icon-locked-dark:after {
    background-position: -35px -489px;
    width: 13px;
    height: 15px;
}

// Publish unpublish
i.erpl_icon-publish:after {
    background-position: -95px -489px;
    width: 15px;
    height: 16px;
}

i.erpl_icon-unpublish:after {
    background-position: -113px -489px;
    width: 15px;
    height: 16px;
}

// Edit
i.erpl_icon-edit:after,
i.erpl_icon-edit-blue:after {
    background-position: -3px -511px;
    width: 13px;
    height: 13px;
}

i.erpl_icon-edit-white:after {
    background-position: -21px -511px;
    width: 13px;
    height: 13px;
}

i.erpl_icon-edit-success:after {
    background-position: -74px -511px;
    width: 13px;
    height: 13px;
}

i.erpl_icon-edit-dark:after {
    background-position: -56px -511px;
    width: 13px;
    height: 13px;
}

// Trash
i.erpl_icon-trash:after,
i.erpl_icon-trash-blue:after {
    background-position: -97px -511px;
    width: 11px;
    height: 13px;
}

i.erpl_icon-trash-white:after {
    background-position: -115px -511px;
    width: 11px;
    height: 13px;
}

i.erpl_icon-trash-success:after {
    background-position: -168px -511px;
    width: 11px;
    height: 13px;
}

i.erpl_icon-trash-dark:after {
    background-position: -150px -511px;
    width: 11px;
    height: 13px;
}

// Eye
i.erpl_icon-eye:after,
i.erpl_icon-eye-blue:after {
    background-position: -1px -554px;
    width: 14px;
    height: 10px;
}

i.erpl_icon-eye-white:after {
    background-position: -20px -554px;
    width: 14px;
    height: 10px;
}

i.erpl_icon-eye-success:after {
    background-position: -76px -554px;
    width: 14px;
    height: 10px;
}

i.erpl_icon-eye-dark:after {
    background-position: -57px -554px;
    width: 14px;
    height: 10px;
}

// Reload
i.erpl_icon-reload:after,
i.erpl_icon-reload-blue:after {
    background-position: -1px -538px;
    width: 12px;
    height: 12px;
}

i.erpl_icon-reload-white:after {
    background-position: -20px -538px;
    width: 12px;
    height: 12px;
}

i.erpl_icon-reload-success:after {
    background-position: -75px -538px;
    width: 12px;
    height: 12px;
}

i.erpl_icon-reload-dark:after {
    background-position: -57px -538px;
    width: 12px;
    height: 12px;
}

// Headphone
i.erpl_icon-headphone:after,
i.erpl_icon-headphone-blue:after {
    background-position: -1px -569px;
    width: 13px;
    height: 11px;
}

i.erpl_icon-headphone-white:after {
    background-position: -20px -569px;
    width: 13px;
    height: 11px;
}

i.erpl_icon-headphone-success:after {
    background-position: -76px -569px;
    width: 13px;
    height: 11px;
}

i.erpl_icon-headphone-dark:after {
    background-position: -57px -569px;
    width: 13px;
    height: 11px;
}

// Inversion des couleurs dans un bouton
.btn.btn-primary:hover,
.btn.btn-dark-primary:hover {
    .erpl_icon-calendar-white:after {
        background-position: -1px -234px;
    }
    .erpl_icon-search-white:after {
        background-position: -83px -1px;
    }
    .erpl_icon-arrow-bottom-white:after {
        background-position: -175px -20px;
    }
    .erpl_icon-arrow-bottom-white:after {
        background-position: -175px -20px;
    }
    i.erpl_icon-plus-bold-white:after {
        background-position: -3px -465px;
        width: 19px;
        height: 19px;
    }
}

.btn.btn-success:hover {
    .erpl_icon-play-mini-white:after,
    .erpl_icon-play-mini:after {
        background-position: -100px -127px;
    }
}

// geolocalization
i.erpl_icon-geolocalization::after {
    background-position: -93px -464px;
    width: 20px;
    height: 22px;
}

i.erpl_icon-geolocalization-dark::after {
    background-position: -129px -464px;
    width: 20px;
    height: 22px;
}

i.erpl_icon-geolocalization-light::after {
    background-position: -149px -464px;
    width: 20px;
    height: 22px;
}

i.erpl_icon-geolocalization-green::after {
    background-position: -166px -464px;
    width: 20px;
    height: 22px;
}

i.erpl_icon-geolocalization-white::after {
    background-position: -111px -464px;
    width: 20px;
    height: 22px;
}